<template>
  <div class="o2a">
    <x-breadcrumbs :items="breadcrumbItems" />

    <main class="o2a__content">
      <router-view />
    </main>
  </div>
</template>

<script>

export default {
  name: 'Ozon2Ali',
  data () {
    return {
      blocks: []
    }
  },

  computed: {
    breadcrumbItems () {
      const routes = [
        { text: 'Экспорт товаров', to: { name: 'mp-to-mp' } }
      ]

      if (this.$route.params.taskId) {
        routes.push({
          text: this.getTaskText()
        })
      }

      return routes
    }
  },

  methods: {
    getTaskText () {
      if (this.$route.params.taskId === 'new') {
        return 'новая задача'
      }

      return `Задача ${this.$route.params.taskId}`
    }
  }
}
</script>

<style lang="stylus">
.o2a
  &__page-heading.heading
    margin-bottom 0

</style>
